
// PAGES
import GrapePaymentPage from './payment/payment.js';
import paymentlistPage from './payment/payment_list.js';
import ApprovalStructurePage from './settings/approval_structure.js';
import paymentSearchPage from './payment/payment_search.js';
import PaymentReportPage from './report/payment_report.js';
import UserSignatures from './settings/user_signatures.js';

export default [
	GrapePaymentPage,
	paymentlistPage,
	paymentSearchPage,
	PaymentReportPage,
	ApprovalStructurePage,
	UserSignatures
];


