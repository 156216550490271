import template from './payment_search_help.html';

class PaymentSearchHelpViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
	};
	
	close()
	{
		this.dialog.close();
	}
};

class PaymentSearchHelpPage 
{
	constructor (bindings)
	{
		this.viewModel = new PaymentSearchHelpViewModel(this);
		this.bindings = bindings;
	};
};

// Register the dialog
export default {
	name: 'PaymentSearchHelp',
	dialog_class: PaymentSearchHelpPage,
	template: template,
	template_filename: 'translation/dialogs/payment_search_help',
	provider: 'ps'
};
