import template from './payment_report.html';

class PaymentReportViewModel
{
	constructor (page)
	{
		this.page = page;
		this.payment_report = ko.observableArray();

		this.payment_category = ko.observableArray();
		this.payment_category_list = ko.observableArray();
		this.payment_category_selected = ko.observable();
		this.payment_category_selected_all = ko.observable(true);
		
		this.payments = ko.observableArray();
		this.filter_person_list = ko.observableArray();
		this.filter_payee_list = ko.observableArray();

		this.selected_people = ko.observableArray();
		this.selected_person = ko.observable();

		this.payment_status = ko.observable();
		this.payment_status_list = ko.observableArray();
		this.payment_status_selected_all = ko.observable(true);

		this.requested_by = ko.observable();
		this.requested_by_id = ko.observable();
		this.payee = ko.observable();
		this.payee_id = ko.observable();

		let today = new Date();
		let firstDay = today.setDate(1);
		let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
		this.date_start = ko.observable(new Date(firstDay).toJSON().slice(0,10));
		this.date_end = ko.observable(new Date(lastDay).toJSON().slice(0,10));
		this.payment_date_start = ko.observable(new Date(firstDay).toJSON().slice(0,10));
		this.payment_date_end = ko.observable(new Date(lastDay).toJSON().slice(0,10));

		this.selected_user = ko.observable(null);
		this.selected_payee = ko.observable(null);

		this.date_start.subscribe((val) => {
			if (this.date_end() && this.date_start())
			{
				if (this.date_end() < val)
				{
					Grape.alerts.alert({type: 'warning', title: 'Warning', message: ('The start date cannot be after the end date.')});
					this.date_end(val);
				}
				else
					this.page.updateData();
			}
		});

		this.date_end.subscribe((val) => {
			if (this.date_end() && this.date_start())
			{
				if (this.date_start() > val)
				{
					Grape.alerts.alert({type: 'warning', title: 'Warning', message: ('The end date cannot be before the start date.')});
					this.date_start(val);
				}
				else
					this.page.updateData();
			}
		});

		this.payment_date_start.subscribe((val) => {
			if (this.payment_date_end() && this.payment_date_start())
			{
				if (this.payment_date_end() < val)
				{
					Grape.alerts.alert({type: 'warning', title: 'Warning', message: ('The end date cannot be before the start date.')});
					this.payment_date_end(val);
				}
				else
					this.page.updateData();
			}
		});

		this.payment_date_end.subscribe((val) => {
			if (this.payment_date_end() && this.payment_date_start())
			{
				if (this.payment_date_start() > val)
				{
					Grape.alerts.alert({type: 'warning', title: 'Warning', message: ('The end date cannot be before the start date.')});
					this.payment_date_start(val);
				}
				else
					this.page.updateData();
			}
		});

		this.selected_user.subscribe((val) => {
			if (val != null)
			{
				this.requested_by(val);
				this.requested_by_id(val.user_id);
				this.add_requested_by_filter(val);
			}
		});

		this.selected_payee.subscribe((val) => {
			if (val != null)
			{
				this.payee(val);
				this.payee_id(val.user_id);
				this.add_payee_by_filter(val);
			}
		});

		this.payment_category_selected.subscribe((val) => {
			if (val.category == 'all')
			{
				this.payment_category_list([]);
				this.payment_category_selected_all(true);
				this.page.updateData();
			}
			else
				this.add_category_filter(val);
		});

		this.payment_status.subscribe((val) => {
			if (val == 'all')
			{
				this.payment_status_selected_all(true);
				this.payment_status_list([]);
				this.page.updateData();
			}
			else
				this.add_status_filter(val);
		});
	};

	show_payment_help()
	{
		Grape.dialog.open('PaymentReportHelp', { employee_id: this.page.bindings.id });
	}

	download_clicked()
	{
		// Select the table
		let table = document.getElementById('tbl_report');    
		if (!table)
		{
			console.error("Payment report table not found");
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Unable to find the payment report table for download.'});
		}

		// Extract data from table
		let data = [];

		// Add headers
		let headers = [];
		for (let cell of table.querySelectorAll('tr:first-child th'))
		{
			headers.push('"' + (cell.innerText || '').replace(/"/g, '""') + '"');
		}
		data.push(headers.join(','));

		// Add row data
		for (let row of table.querySelectorAll('tr:not(:first-child)'))
		{
			let row_data = [];
			for (let cell of row.querySelectorAll('td'))
			{
				row_data.push('"' + (cell.innerText || '').replace(/"/g, '""') + '"');
			}
			data.push(row_data.join(','));
		}

		// Combine into CSV string
		let csvContent = data.join('\n');
		
		// Create a Blob with the CSV data
		let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

		// Create a download link and trigger it
		let link = document.createElement("a");
		if (link.download !== undefined)
		{
			let url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", "payment_report.csv");
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Your browser does not support downloading CSV files.'});
	}

	add_category_filter(val)
	{
		this.payment_category_selected_all(false);

		if (!this.payment_category_list().some(category => category.id === val.payment_category_id))
		{
			this.payment_category_list.push({
				'name': val.category,
				'id': val.payment_category_id
			});
		}
	
		this.page.updateData();
	}

	add_status_filter(val)
	{
		this.payment_status_selected_all(false);

		if (!this.payment_status_list().some(status => status.name === val))
		{
			this.payment_status_list.push({
				'name': val
			});
		}

		this.page.updateData();
	};

	add_requested_by_filter(val)
	{
		this.selected_user(null);
		if (!this.filter_person_list().some(user => user.user_id === val.user_id))
		{
			this.filter_person_list.push(val);
			this.page.updateData();
		}
	}

	remove_requested_by_filter(data)
	{
		this.filter_person_list.remove(data);
		this.page.updateData();
	};

	add_payee_by_filter(val)
	{
		this.selected_payee(null);
		if (!this.filter_payee_list().some(user => user.payee_id === val.payee_id))
		{
			this.filter_payee_list.push(val);
			this.page.updateData();
		}
	}

	remove_payee_by_filter(data)
	{
		this.filter_payee_list.remove(data);
		this.page.updateData();
	};

	remove_category_filter(data)
	{
		this.payment_category_list.remove(data);
		if (this.payment_category_list().length === 0)
		{
			this.payment_category_selected().category = 'all';
			this.payment_category_selected().payment_category_id = 0;
		}
		this.page.updateData();
	};

	remove_status_filter(data)
	{
		this.payment_status_list.remove(data);
		if (!this.payment_status_list().length > 0)
			this.payment_status('all');
		this.page.updateData();
	};
};

class PaymentReportPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new PaymentReportViewModel(this);
	}

	async init()
	{
		window.current_page_title(tr('Payment Reports'));
		try {
			let res = await Grape.fetches.postJSON('/payment/list_payment_category', {});
			if (res.status != 'ERROR')
			{
				res.unshift({payment_category_id: 0, category: 'all'});
				this.viewModel.payment_category(res);
			}
			else
				Grape.alerts.apiError(res);
		} catch (error) {
			console.error(error)
			Grape.alerts.alert({type: 'error', title: window.tr('Error'), message: window.tr('An error occurred while loading the payment categories: ' + error.message)});
		}
	};

	async updateData()
	{
		let data = {
			date_start: this.viewModel.date_start(), 
			date_end: this.viewModel.date_end(),
			payment_date_start: this.viewModel.payment_date_start(),
			payment_date_end: this.viewModel.payment_date_end(),
			sortorder: 'DESC',
			sortfield: 'payment_date'
		};

		if (data.date_start == '')
			data.date_start = null;
		
		if (data.date_end == '')
			data.date_end = null;

		if (data.payment_date_start == '')
			data.payment_date_start = null;

		if (data.payment_date_end == '')
			data.payment_date_end = null;

		data.filter_person_list = [];
		this.viewModel.filter_person_list().forEach((item) => {
			data.filter_person_list.push(item.user_id);
		});

		data.filter_person_list = JSON.stringify(data.filter_person_list);
		data.filter_person_list = data.filter_person_list.replace('[', '{');
		data.filter_person_list = data.filter_person_list.replace(']', '}');

		data.filter_payee_list = [];
		this.viewModel.filter_payee_list().forEach(function (item) {
			data.filter_payee_list.push(item.payee_id);
		});

		data.filter_payee_list = JSON.stringify(data.filter_payee_list);
		data.filter_payee_list = data.filter_payee_list.replace('[', '{');
		data.filter_payee_list = data.filter_payee_list.replace(']', '}');
		
		data.category = [];
		if (this.viewModel.payment_category_selected_all())
			data.category = '{}';
		else
		{
			this.viewModel.payment_category_list().forEach((item) => {
				data.category.push(item.id);
			});

			data.category = JSON.stringify(data.category);
			data.category = data.category.replace('[', '{');
			data.category = data.category.replace(']', '}');
		}

		data.status = [];

		if (this.viewModel.payment_status_selected_all())
			data.status = '{}';
		else
		{
			this.viewModel.payment_status_list().forEach((item) => {
				data.status.push(item.name);
			});

			data.status = JSON.stringify(data.status);
			data.status = data.status.replace('[', '{');
			data.status = data.status.replace(']', '}');
		}

		let res = await Grape.fetches.postJSON('/payment/payment_report', data);
		if (res.status != 'ERROR')
			this.viewModel.payment_report(res.report);
	};
};

export default {
	route: '[/]payment_report/report',
	page_class: PaymentReportPage,
	template_filename: 'translation/payment_report',
	template: template
};
