import template from './user_signatures.html';

class UserSignaturesViewModel
{
	constructor (page)
	{
		this.page = page;
		this.user_signatures = ko.observableArray();
	};

	async upload_signature(user_id)
	{
		await Grape.dialog.open('UserSignatureUpload', { user_id: user_id });
		this.page.updateData();
	}
	
	user_signature_help()
	{
		Grape.dialog.open('UserSignatureHelp', { employee_id: this.page.bindings.id });
	};
};

class UserSignatures
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new UserSignaturesViewModel(this);
	}

	init()
	{
		document.title = window.tr('Add/Edit user signatures');
		window.current_page_title(tr('User Signatures'));
	}

	async updateData()
	{
		let res = await Grape.fetches.getJSON('/api/record', {
			table: 'v_payment_users',
			schema: 'grape_payment_approval',
			limit: 1000000
		});
		if (res.status != 'ERROR')
			this.viewModel.user_signatures(res.records);
		else
			Grape.alerts.apiError(res);
	};
};

export default {
	route: '[/]payment_approval/user_signatures',
	page_class: UserSignatures,
	template: template,
	template_filename: 'translation/user_signatures'
};

