import template from './user_signature_help.html';

class UserSignatureHelpViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
	};
	
	close()
	{
		this.dialog.close();
	}
};

class UserSignatureHelpPage
{
	constructor (bindings)
	{
		this.viewModel = new UserSignatureHelpViewModel(this);
		this.bindings = bindings;
	};
};

// Register the dialog
export default {
	name: 'UserSignatureHelp',
	dialog_class: UserSignatureHelpPage,
	template: template,
	template_filename: 'translation/dialogs/user_signature_help',
	provider: 'ps'
};
