import template from './add_structure_line.html';

class SettingsAddStructureLineViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.user_id = ko.observable();
		this.selected_user = ko.observable();
		this.disable_fields = ko.observable(false);
		this.limits = ko.observable(false);
		this.payment_category_selected = ko.observable();
		this.department_selected = ko.observable();
		this.title = ko.observable();

		this.current_user_line = ko.observable({
			"user_id":0,
			"use_limit": false,
			"lower_limit":'',
			"upper_limit":'',
			"priority": '',
			"edit_requisiton": false,
			"allowed_to_select_supplier": false,
			"force_to_select_supplier": false,
			"authorized_user": false,
			"allowed_to_void": false,
			"allowed_to_view_download_requisition": false,
			"allowed_to_upload": false,
			"fullnames": ''
		});

		this.selected_user.subscribe((val) => {
			this.current_user_line().fullnames = val.fullnames;
			this.current_user_line().user_id = val.user_id;
			this.user_id(val.user_id);
		});
	};

	close()
	{
		this.dialog.close();
	}

	async add_structure()
	{
		let res = await Grape.fetches.postJSON('/payment/department_upsert', {department: $('#txtDepartment').val()});
		if (res.status != 'ERROR')
			Grape.alerts.alert({type: 'success', message: window.tr('Successfully added department.'), title: window.tr('Success')});
	};

	async save_approval_structure_line(v, e)
	{
		if (!v.current_user_line().fullnames)
			Grape.alerts.alert({type: 'error', message: window.tr('Approver name missing'), title: window.tr('Error')});

		else if (!v.current_user_line().priority)
			Grape.alerts.alert({type: 'error', message: window.tr('Priority missing'), title: window.tr('Error')});

		else if (parseInt(v.current_user_line().lower_limit) < 0 || parseInt(v.current_user_line().upper_limit) < 0)
			Grape.alerts.alert({type: 'error', message: window.tr('Please use positive numbers for the limits.'), title: window.tr('Error')});

		else if (parseInt(v.current_user_line().priority) < 0)
			Grape.alerts.alert({type: 'error', message: window.tr('Please use a positive number for the priority.'), title: window.tr('Error')});

		else
		{
			if (parseInt(v.current_user_line().lower_limit) > parseInt(v.current_user_line().upper_limit))
				Grape.alerts.alert({type: 'error', title: window.tr('Error'), message: window.tr('Lower limit is greater than upper limit.')});
			else
			{
				if (!v.current_user_line().approval_structure_id)
					v.current_user_line().approval_structure_id = 0;

				if (!this.limits())
				{
					v.current_user_line().lower_limit = 0;
					v.current_user_line().upper_limit = 0;
				}

				let res = await Grape.fetches.postJSON('/payment/approval_structure_upsert', {
					approval_structure_id: v.current_user_line().approval_structure_id,
					requisition_type_id : this.payment_category_selected(),
					department_id: this.department_selected(),
					user_id: this.user_id(),
					use_limit: this.limits(),
					lower_limit: v.current_user_line().lower_limit,
					upper_limit: v.current_user_line().upper_limit,
					priority: v.current_user_line().priority,
					allowed_to_select_supplier: v.current_user_line().allowed_to_select_supplier,
					force_to_select_supplier: v.current_user_line().force_to_select_supplier,
					edit_requisiton: v.current_user_line().edit_requisiton,
					authorized_user : v.current_user_line().authorized_user,
					allowed_to_void: v.current_user_line().allowed_to_void,
					allowed_to_view_download_requisition: v.current_user_line().allowed_to_view_download_requisition,
					allowed_to_upload: v.current_user_line().allowed_to_upload
				});

				if (res.status == 'OK')
				{
					Grape.alerts.alert({type: 'success', message: window.tr('Line structure successfully saved.'), title: window.tr('Success')});
					this.dialog.close(true);
					this.current_user_line(null);
				}
				else
					Grape.alerts.alert({type: 'error', title: window.tr('Error'), 	message: window.tr('Something went wrong saving structure line.')});
			}
		}
	};
};

class SettingsAddStructureLinePage 
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SettingsAddStructureLineViewModel(this);

		if (this.bindings.edit)
			this.viewModel.title('Editing Structure Line: ' + bindings.current_user.fullnames);
		else
			this.viewModel.title('Add Structure Line');

		if (bindings.current_user)
		{
			this.viewModel.current_user_line(bindings.current_user);
			if (this.viewModel.current_user_line().use_limit)
				this.viewModel.limits(bindings.current_user.use_limit);
		}
		else
			this.viewModel.title('Add Structure Line');

		if (bindings.disable_fields)
			this.viewModel.disable_fields(bindings.disable_fields);

		if (bindings.requisition_type_id)
			this.viewModel.payment_category_selected(bindings.requisition_type_id);
		
		if (bindings.department_id)
			this.viewModel.department_selected(bindings.department_id);

		if (bindings.current_user.user_id)
			this.viewModel.user_id(bindings.current_user.user_id);
	}
};

export default {
	name: 'AddStructureLine',
	dialog_class: SettingsAddStructureLinePage,
	template: template,
	template_filename: 'translation/dialogs/add_structure_line',
	provider: 'ps'
};
