
// COMPONENTS
import payment_users_search from "./search/payment_users_search.js";
import requested_by_search_component from "./search/requested_by_search_component.js";
import users_approval_structure from './search/users_approval_structure.js';

export default [
	payment_users_search,
	requested_by_search_component,
	users_approval_structure
];
