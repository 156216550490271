import template from './payment_help.html';

class PaymentHelpViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
	};
	
	close()
	{
		this.dialog.close();
	}
};

class PaymentHelpPage
{
	constructor (bindings)
	{
		this.viewModel = new PaymentHelpViewModel(this);
		this.bindings = bindings;
	};
};

// Register the dialog
export default {
	name: 'PaymentHelp',
	dialog_class: PaymentHelpPage,
	template: template,
	template_filename: 'translation/dialogs/payment_help',
	provider: 'ps'
};
