
// DIALOGS
import GrapeAddPayment from './payment/payment.js';
//import GrapeAddAuthorizedPerson from './authorized_by/authorized_by.js';
import SettingsAddStructurePage from './settings/add_structure.js';
import SettingsAddStructureLinePage from './settings/add_structure_line.js';
import UserSignatureUploadPage from './settings/user_signature_upload.js';

// HELP DIALOGS
import PaymentHelp from './help/payment_help.js';
import PaymentSearchHelp from './help/payment_search_help.js';
import PaymentReportHelp from './help/payment_report_help.js';
import StructureLineHelp from './help/structure_line_help.js';
import PaymentApprovalStructureHelp from './help/payment_approval_structure_help.js';
import UserSignatureHelp from './help/user_signature_help.js';

export default [
	GrapeAddPayment,
	//GrapeAddAuthorizedPerson,
	SettingsAddStructurePage,
	SettingsAddStructureLinePage,
	UserSignatureUploadPage,
	PaymentHelp,
	PaymentReportHelp,
	UserSignatureHelp, 
	PaymentApprovalStructureHelp,
	StructureLineHelp,
	PaymentSearchHelp
	];
