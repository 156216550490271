import template from './payment.html';

class GrapePaymentViewModel
{
	constructor (page)
	{
		this.page = page;
		this.filter = ko.observableArray();
		this.current_user_id = ko.observable();
	};

	show_payment_help()
	{
		Grape.dialog.open('PaymentHelp', { employee_id: this.page.bindings.id });
	};

	async add_payment()
	{
		await Grape.dialog.open('GrapeAddPayment', null);
		this.page.updateData();
	};

	async edit_payment(row)
	{
		let data = ko.toJS(row);
		data.view = false;
		data.edit = true;
		await Grape.dialog.open('GrapeAddPayment',{ data: data });
		this.page.updateData();
	};

	async approve_payment(res)
	{
		let response = await Grape.alerts.confirm({type: 'info', title: window.tr('Confirm'), message: window.tr('Are you sure you want to approve this payment?')});
		if (response)
		{
			let result = await Grape.fetches.postJSON('/payment/approve', {payment_id : res.payment_id });
			if (result.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: window.tr('Successfully approved.'), title: window.tr('Success')});
				this.page.updateData();
			}
			else if(result.status == 'ERROR')
				Grape.alerts.apiError(result);
			else
				Grape.alerts.alert({type: 'error', message: window.tr('Something went wrong...'), title: window.tr('Error')});
		}
	};

	async reject_payment(res)
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: window.tr('Confirm'), message: window.tr('Are you sure you want to reject this payment?')});
		if (response)
		{
			let reason = await Grape.alerts.prompt({type: 'warning', title: '', message: window.tr('Please provide a reason of rejecting this payment')});
			if (reason)
			{
				let result = await Grape.fetches.postJSON('/payment/reject', {payment_id : res.payment_id, reason: reason });
				if (result.status == 'OK')
				{
					Grape.alerts.alert({type: 'success', message: window.tr('Successfully rejected.'), title: window.tr('Success')});
					this.page.updateData();
				}
				else if (result.status == 'ERROR') 
					Grape.alerts.apiError(result);
				else
					Grape.alerts.alert({type: 'error', message: window.tr('Something went wrong...'), title: window.tr('Error')});
			}
			else
				Grape.alerts.alert({type: 'error', message: window.tr('Reason must be provided'), title: window.tr('Error')});
		}
	};

	async delete_payment(res)
	{
		let response = await Grape.alerts.confirm({type: 'warning', title: window.tr('Confirm'), message: window.tr('Are you sure you want to delete this payment?')});
		if (response)
		{
			let result = await Grape.fetches.postJSON('/payment/delete', {payment_id : res.payment_id });
			if(result.status == 'OK')
			{
				Grape.alerts.alert({type: 'success', message: window.tr('Successfully deleted.'), title: window.tr('Success')});
				this.page.updateData();
			}
			else if(result.status == 'ERROR')
				Grape.alerts.apiError(result);
			else
				Grape.alerts.alert({type: 'error', message: window.tr('Something went wrong...'), title: window.tr('Error')});
		}
	};

	async view_payment(row)
	{
		let data = ko.toJS(row);
		data.view = true;
		data.edit = false;
		
		if (data.status == 'rejected')
			data.view = true;

		await Grape.dialog.open('GrapeAddPayment', { data: data });
		this.page.updateData();
	};

	async view_payment_approval(row)
	{
		let data = ko.toJS(row);
		data.view = true;
		data.approval = true;
		data.edit = false;

		await Grape.dialog.open('GrapeAddPayment', { data: data });
		this.page.updateData();
	};
};

class GrapePaymentPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new GrapePaymentViewModel(this);
		this.payments = ko.observableArray();
		this.pending_payments = ko.observableArray();
	};

	async init()
	{
		document.title = 'My Payments';
		window.current_page_title(tr('My Payments'));
		let buttonstring = [];

		let columns = [
		{
			field: 'payment_id', 
			title: window.tr('ID'), 
			sortable: true, align : 'left',
			formatter: (value, row, index) => {
				if (row.status == 'void')
					return '<span style="color: grey; ">'+ row.payment_id +'</span>';
				else
					return row.payment_id;
			}
		},
		{
			field: 'requested_by',
			title: window.tr('Requested by'),
			sortable: true ,
			align : 'left',
			formatter: (value, row, index) => {
				if (row.status == 'void')
					return '<span style="color: grey; ">'+ row.requested_by +'</span>';
				else
					return row.requested_by;
			}
		},
		{
			field: 'assigned_to', 
			title: window.tr('Assigned to'), 
			sortable: true,
			align : 'left', 
			formatter: (value, row, index) => {
				if (row.status == 'void')
					return '<span style="color: grey; ">'+ row.assigned_to +'</span>';
				else
					return row.assigned_to;
			}
		},
		{
			field: 'date_created', 
			title: window.tr('Date created'), 
			sortable: true, 
			align : 'left', 
			formatter: (value, row, index) => {
				if (row.status == 'void') 
					return '<span style="color: grey; ">'+ row.date_created +'</span>';
				else
					return row.date_created;
			}
		},
		{
			field: 'payment_date', 
			title: window.tr('Payment date'), 
			sortable: false, 
			align : 'left',
			formatter: (value, row, index) => {
				if (row.status == 'void') 
					return '<span style="color: grey; ">'+ row.payment_date +'</span>';
				else
					return row.payment_date;
			}
		},
		{
			field: 'description', 
			title: window.tr('Description'), 
			sortable: false, 
			align : 'left',
			formatter: (value, row, index) => {
				if (row.status == 'void') 
					return '<span style="color: grey; ">'+ row.description +'</span>';
				else
					return row.description;
			}
		},
		{
			field: 'total', 
			title: window.tr('Total Amount (Excl. vat)'),
			sortable: false,
			align : 'left',
			formatter: (value, row, index) => {
				if (row.status == 'void') 
					return '<span style="color: grey; ">'+ 'R ' + (row.total).toFixed(2) +'</span>';
				else
					return 'R ' + (row.total).toFixed(2);
			}
		},
		{
			field: 'status',
			title: window.tr('Status'),
			sortable: false,
			align : 'left',
			color: 'orange',
			formatter: (value, row, index) => {
				if (row.status == 'void') 
					return '<span style="color: grey; ">'+ row.status +'</span>';
				else
					return row.status;
			}
		},
		{
			field: 'Action',
			title: tr('Actions'),
			sortable: false,
			align: 'right', 
			formatter:(value, row, index) => {
				buttonstring = [];
				if (row.status == 'in_progress')
				{
					buttonstring.push('<button class="ps-btn-primary ps-btn-sm view edit_payment_button "><span class="fa fa-pencil"></span></button>');
					buttonstring.push('<button class="ps-btn-default ps-btn-sm view view_payment_button "><span class="fa fa-eye" style="color:green"></span></button>');
					buttonstring.push('<button class="ps-btn-danger ps-btn-sm view delete_payment_button "><span class="fa fa-trash"></span></button>');
				}
				else if (row.status == 'rejected')
					buttonstring.push('<button class="ps-btn-default ps-btn-sm view view_payment_button "><span class="fa fa-eye" style="color:red"></span></button>');
				else if (row.status == 'void') 
				{
					buttonstring.push('<button class="ps-btn-primary ps-btn-sm view edit_payment_button " disabled><span class="fa fa-pencil"></span></button>');
					buttonstring.push('<button class="ps-btn-default ps-btn-sm view view_payment_button "><span class="fa fa-eye"></span></button>');
					buttonstring.push('<button class="ps-btn-danger ps-btn-sm view delete_payment_button " disabled><span class="fa fa-trash"></span></button>');
			
				}
				else if (row.status == 'completed') 
				{
					buttonstring.push('<button class="ps-btn-primary ps-btn-sm view edit_payment_button " disabled><span class="fa fa-pencil"></span></button>');
					buttonstring.push('<button class="ps-btn-default ps-btn-sm view view_payment_button "><span class="fa fa-eye"></span></button>');
					buttonstring.push('<button class="ps-btn-danger ps-btn-sm view delete_payment_button " disabled><span class="fa fa-trash"></span></button>');
				}
				else
				{
					buttonstring.push('<button class="ps-btn-primary ps-btn-sm view edit_payment_button "><span class="fa fa-pencil"></span></button>');
					buttonstring.push('<button class="ps-btn-default ps-btn-sm view view_payment_button "><span class="fa fa-eye"></span></button>');
					buttonstring.push('<button class="ps-btn-danger ps-btn-sm view delete_payment_button "><span class="fa fa-trash"></span></button>');
				}

				let return_string = buttonstring.join('');
				return return_string;
			},
			events:{
				'click .approve_payment_button': (e, value, row, index) => {
					this.viewModel.approve_payment(row.payment_id);
				},
				'click .reject_payment_button': (e, value, row, index) => {
					this.viewModel.reject_payment(row.payment_id);
				},
				'click .edit_payment_button': (e, value, row, index) => {
					this.viewModel.edit_payment(row);
				},
				'click .view_payment_button': (e, value, row, index) => {
					this.viewModel.view_payment(row);
				},
				'click .view_payment_button_approval': (e, value, row, index) => {
					this.viewModel.view_payment_approval(row);
				},
				'click .delete_payment_button': (e, value, row, index) => {
					this.viewModel.delete_payment(row);
				}
			}
		}];

		this.tbl_mypayments = $("#tbl_mypayments").bootstrapTable({
			columns: columns,
			pagination: true,
			pageList: [10, 20, 50, 100],
			pageSize: 10,
			sidePagination: 'server',
			dataToolbar: '#toolbar',
			ordering: true,
			dataField: 'records',
			onPageChange: (number, size) => {
				this.updateData();
			},
			onSort: (sortName, sortOrder) => {
				this.updateData();
			}
		});

		let approval_columns = [
			
			{
				field: 'payment_id', 
				title: window.tr('ID'), 
				sortable: true, 
				align : 'left'
			},
			{
				field: 'requested_by', 
				title: window.tr('Requested by'), 
				sortable: true , 
				align : 'left'
			},
			{
				field: 'assigned_to', 
				title: window.tr('Assigned to'), 
				sortable: true , 
				align : 'left'
			},
			{
				field: 'date_created', 
				title: window.tr('Date created'), 
				sortable: true, 
				align : 'left'
			},
			{
				field: 'payment_date', 
				title: window.tr('Payment date'), 
				sortable: false, 
				align : 'left'
			},
			{
				field: 'description', 
				title: window.tr('Description'), 
				sortable: false, 
				align : 'left'
			},
			{
				field: 'total', 
				title: window.tr('Total Amount (Excl. vat)'), 
				sortable: false,
				align : 'left',
				formatter: (value, row, index) =>
				{
					return 'R ' + (row.total).toFixed(2);
				}
			},
			{
				field: 'status', 
				title: window.tr('Status'), 
				sortable: false, 
				align : 'left',
				formatter: (value, row, index) =>
				{
					return '<span class="fa fa-hourglass-half" style="color:orange; font-size: 23px;"></span>' + row.status
				}
			},
			{
				field: 'Action', 
				title: window.tr('Actions'),
				sortable: false,
				align: 'right', 
				formatter: (value, row, index) =>
				{
					buttonstring = [];
					buttonstring.push('<button class="ps-btn-default ps-btn-sm view view_payment_button_approval "><span class="fa fa-eye"></span></button>',);
					buttonstring.push('<button class="ps-btn-danger ps-btn-sm view delete_payment_button "><span class="fa fa-trash"></span></button>');
					let return_string = buttonstring.join('');
					return return_string;
				},
				events:{
					'click .approve_payment_button': (e, value, row, index) =>
					{
						this.viewModel.approve_payment(row.payment_id);
					},
					'click .reject_payment_button': (e, value, row, index) =>
					{
						this.viewModel.reject_payment(row.payment_id);
					},
					'click .view_payment_button_approval': (e, value, row, index) =>
					{
						this.viewModel.view_payment_approval(row);
					},
					'click .edit_payment_button': (e, value, row, index) =>
					{
						this.viewModel.edit_payment(row);
					},
					'click .delete_payment_button': (e, value, row, index) =>
					{
						this.viewModel.delete_payment(row);
					}
				}
			}
		];

		this.tbl_pendingpayments = $('#tbl_pendingpayments').bootstrapTable({
			columns: approval_columns,
			sidePagination: 'server',
			dataToolbar: '#toolbar',
			dataField: 'records',
			onPageChange: (number, size) =>
			{
				this.updateData();
			},
			onSort: (sortName, sortOrder) =>
			{
				this.updateData();
			}
		});
	}

	async updateData()
	{
		let filter = [];
		if (this.viewModel.filter().length > 0)
			filter = ko.toJS(this.viewModel.filter());

		let options = {
			table: 'v_payment_list', 
			schema: 'grape_payment_approval',
			offset: 0,
			limit: 10,
			sortfield: 'payment_id',
			sortorder: 'DESC',
			filter: filter
		};

		let res = await Grape.fetches.getJSON('/api/record', options);
		if (!res.records)
			res.records = [];

		$('#tbl_mypayments').bootstrapTable('load', res);
		this.payments(res);
		
		let res_pending = await Grape.fetches.postJSON('/payment/get_pending_list', {});
		if (!res_pending.records)
			res_pending.records = [];

		
		$('#tbl_pendingpayments').bootstrapTable('load', res_pending);
		this.pending_payments(res_pending);
	}
};

export default {
	route: `[/]payment_approval/payment`,
	page_class: GrapePaymentPage,
	template: template,
	template_filename: 'translation/payment'
};
