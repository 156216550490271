
/* Application/Library entry-point */
import components from './components/index.js';
import dialogs from './dialogs/index';
import pages from './pages/index.js';
import d_caches from './caches/index.js';
import package_json from '../package.json';
import payment_translated_pages from './translations/index.js';

window.Grape.modules.push(package_json);
window.Grape.cache.registerCaches(d_caches);
window.Grape.component.registerComponents(components);
window.Grape.pages.registerPages(pages);
window.Grape.dialog.registerDialogs(dialogs);
window.payment_translated_pages = payment_translated_pages;
