import template from './approval_structure.html';

class AdminApprovalStructureViewModel
{
	constructor (page)
	{
		this.page = page;
		this.approval_structure = ko.observableArray();
		this.departments = ko.observableArray();
		this.payment_category = ko.observableArray();
		this.department_selected = ko.observable();
		this.payment_category_selected = ko.observable();
		this.user = ko.observable();
		this.user_id = ko.observable();

		this.department_selected.subscribe((newText) => {
			if (this.payment_category_selected() != null && this.department_selected() != null)
				this.page.load_approval_structure();
		});
	
		this.payment_category_selected.subscribe((newText) => {
			if (this.payment_category_selected() != null && this.department_selected() != null)
				this.page.load_approval_structure();
		});
	};

	async delete_approval_structure_line(data)
	{
		let res = await Grape.fetches.postJSON('/payment/delete_approval_structure_line', { approval_structure_id: data.approval_structure_id });
		if (res.status != 'ERROR')
			this.page.updateData();
		else
			Grape.alerts.apiError(res);
	}

	async add_structure()
	{
		await Grape.dialog.open('AddStructure', {});
		this.page.updateData();
	};

	/*async save_approval_structure_line(data)
	{
		if (this.user_id())
		{
			if (parseInt(data.lower_limit) > parseInt(data.upper_limit))
				Grape.alerts.alert({type: 'error', title: window.tr('Error'), message: window.tr('Lower limit is greater than upper limit.')});
			else
			{
				let res = await Grape.fetches.postJSON('/payment/approval_structure_upsert', {
					approval_structure_id: data.approval_structure_id,
					requisition_type_id :this.payment_category_selected(),
					department_id: this.department_selected(),
					user_id: this.user_id(),
					use_limit: data.use_limit,
					lower_limit: data.lower_limit,
					upper_limit: data.upper_limit,
					priority: data.priority,
					allowed_to_select_supplier: data.allowed_to_select_supplier,
					force_to_select_supplier: data.force_to_select_supplier,
					edit_requisiton: data.edit_requisiton,
					authorized_user : data.authorized_user,
					allowed_to_void: data.allowed_to_void,
					allowed_to_view_download_requisition: data.allowed_to_view_download_requisition,
					allowed_to_upload: data.allowed_to_upload
				});
				if (res.status != 'ERROR')
					this.page.updateData();
			}
		}
		else
			Grape.alerts.alert({type: 'error', title: window.tr('Error'), message: window.tr('Please enter user.')});
	}*/

	async edit_approval_structure(data)
	{
		let res = await Grape.fetches.getJSON('/api/record', {
			table: 'v_payment_users' ,
			schema: 'grape_payment_approval',
			limit: 1000000
		});
		if (res.records != null)
		{
			await Grape.show_dialog('AddStructureLine', { users: res.records, current_user: data, requisition_type_id: this.payment_category_selected(), department_id: this.department_selected()});
			this.page.updateData();
		}
	}

	async view_approval_structure(data)
	{
		await Grape.dialog.open('AddStructureLine', { disable_fields: true, current_user: data});
		this.page.updateData();
	}

	show_payment_approval_structure_help()
	{
		Grape.dialog.open('PaymentApprovalStructureHelp', { employee_id: this.page.bindings.id });
	}
	
	structure_line_help()
	{
		Grape.dialog.open('StructureLineHelp', { employee_id: this.page.bindings.id });
	};

	async add_approval_line(v, e)
	{
		if (this.department_selected() == '' || this.department_selected() == null)
		{
			Grape.alerts.alert({type: 'warning', message: window.tr('Please select a department.'), title: window.tr('Warning')});
			return;
		}

		if ($('#ddlpaymentcategory').val() == '' || $('#ddlpaymentcategory').val() == null)
		{
			Grape.alerts.alert({type: 'warning', message: window.tr('Please select a requisition type.'), title: window.tr('Warning')});
			return;
		}
		let approval_structure = v.approval_structure;

		approval_structure.unshift({
			'approval_structure_id':0,
			'requisition_type_id':0,
			'department_id':0,
			'user_id':0,
			'use_limit': false,
			'lower_limit':'',
			'upper_limit':'',
			'priority':'',
			'allowed_to_select_supplier':false,
			'force_to_select_supplier':false,
			'edit_requisiton':false,
			'authorized_user' : false,
			'allowed_to_void':false,
			'allowed_to_view_download_requisition' : false,
			'allowed_to_upload' : false,
			'date_created':'',
			'fullnames':'',
			'department':''
		});

		let res = await Grape.fetches.getJSON('/api/record', {
			table: 'v_payment_users' ,
			schema: 'grape_payment_approval',
			limit: 1000000
		});

		if (res.records != null)
		{
			await Grape.dialog.open('AddStructureLine', { users: res.records, current_user: e, requisition_type_id: this.payment_category_selected(), department_id: this.department_selected()});
			this.page.updateData();
		}
	}
};

class AdminApprovalStructure
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new AdminApprovalStructureViewModel(this);
	}

	async init()
	{
		document.title = window.tr('Add/Edit Approval Structure');
		window.current_page_title(tr('Add/Edit Approval Structure'));
		try {
			let res = await Grape.fetches.getJSON('/api/record', {
				schema: 'grape_payment_approval',
				table: 'department',
				limit: 1000000
			});
			if (res.status != 'ERROR')
				this.viewModel.departments(res.records);
			else
				Grape.alerts.apiError(res);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({type: 'error', title: 'Error', message: window.tr('An error occurred while loading: ' + res.message)});
		}

		try {
			let res = await Grape.fetches.getJSON('/api/record', {
				schema: 'grape_payment_approval',
				table: 'payment_category',
				limit: 1000000
			});
			if (res.status != 'ERROR')
				this.viewModel.payment_category(res.records);
			else
				Grape.alerts.apiError(res);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({type: 'error', title: 'Error', message: window.tr('An error occurred while loading: ' + res.message)});
		}
	}

	async updateData()
	{
		try {
			let res = await Grape.fetches.getJSON('/api/record', {
				schema: 'grape_payment_approval',
				table: 'department',
				limit: 1000000
			});

			if (res.status != 'ERROR')
				this.viewModel.departments(res.records);
			else
				Grape.alerts.apiError(res);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({type: 'error', title: 'Error', message: window.tr('An error occurred while loading departments: ' + res.message)});
		}

		if (this.viewModel.department_selected() != null && this.viewModel.payment_category_selected() != null)
			this.load_approval_structure()
	};

	async load_approval_structure()
	{
		let res = await Grape.fetches.getJSON('/api/record', {
			table: 'v_approval_structure',
			schema: 'grape_payment_approval' ,
			limit: 1000000,
			filter: [{ 
					value: this.viewModel.department_selected(),
					operand: '=',
					field: 'department_id'
				}, {
					value: this.viewModel.payment_category_selected(),
					operand: '=',
					field: 'requisition_type_id'
				}]
		});

		if (res.status != 'ERROR')
			this.viewModel.approval_structure(res.records);
		else
			Grape.alerts.apiError(res);
	}
};

export default {
	route: '[/]payment_approval/approval_structure',
	page_class: AdminApprovalStructure,
	template: template,
	template_filename: 'translation/approval_structure'
};

